/** @jsx jsx */
import { jsx } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Logo from "./logo"
import Navigation from "./navigation"

import "../assets/scss/style.scss"
import Footer from "./footer"
import CookieConsent from "react-cookie-consent"

const query = graphql`
  query LayoutQuery {
    site {
      siteMetadata {
        siteTitle: title
      }
    }
  }
`

const Layout = ({ children, className, props }) => {
  const { site } = useStaticQuery(query)
  const { siteTitle } = site.siteMetadata

  return (
    <div className="primary-container">
      <Header>
        <Logo title={siteTitle} />
        <Navigation />
        {/* <div sx={layoutStyle.theme}>
          <Theme/>
        </div> */}
      </Header>
      <main className={"container " + className}>{children}</main>
      <Footer />
      <CookieConsent
        enableDeclineButton
        location="bottom"
        buttonText="Verstanden"
        declineButtonText="Ablehnen"
        cookieName="gatsby-gdpr-cookie"
        style={{
          fontSize: "18px",
          lineHeight: "1.5",
          backgroundColor: "var(--green-2)",
        }}
        buttonStyle={{
          fontSize: "16px",
          backgroundColor: "var(--green-5)",
          color: "var(--white)",
          textTransform: "uppercase",
          borderRadius: "6px",
          padding: "10px 16px",
        }}
        declineButtonStyle={{
          fontSize: "16px",
          backgroundColor: "var(--green-1)",
          color: "var(--green-5)",
          textTransform: "uppercase",
          borderRadius: "6px",
          padding: "10px 16px",
        }}
        containerClasses="cookie-consent"
      >
        Wir nutzen Cookies um Ihnen die bestmögliche Browsing-Erfahrung zu
        bieten.
      </CookieConsent>
    </div>
  )
}

export default Layout
