/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"

const Footer = () => (
  <footer className="site-footer">
    <div className="grid-container grids col-1 sm-2 lg-3">
      <div>
        <p className="p-bold">Guido Ikierski & Philipp Wolff </p>
        <p>
          BELMOT<sup>&reg;</sup> Markenagentur
        </p>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://goo.gl/maps/wvBHaTuhV4u1JQDt6"
          >
            Lohner Weg 32
            <br />
            30916 Isernhagen
          </a>
        </p>
      </div>
      <div>
        <p>
          Telefon:{" "}
          <a target="_blank" rel="noopener noreferrer" href="tel:05139983330">
            05139 - 983330
          </a>
        </p>
        <p>
          Mobil:{" "}
          <a target="_blank" rel="noopener noreferrer" href="tel:01716201108">
            0171 - 6201108
          </a>
        </p>
        <p>Fax: 05139 - 983331</p>
        <p>
          Mail:{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="mailto:guido.ikierski@gmx.de"
          >
            guido.ikierski@gmx.de
          </a>
        </p>
      </div>
      <div>
        <p>
          <Link to="/impressum">Impressum</Link>
        </p>
        <p>
          <Link to="/datenschutz">Datenschutz</Link>
        </p>
      </div>
    </div>
  </footer>
)

export default Footer
