import React from "react"
import { Link } from "gatsby"

const Logo = props => (
  <div className="site-logo">
    <Link to="/">
      <p className="site-logo--head">
        <span>
          BELMOT<sup>&reg;</sup>
        </span>
        <br />
        Markenagentur
      </p>
      <p className="site-logo--subhead">Guido Ikierski & Philipp Wolff</p>
    </Link>
  </div>
)

export default Logo
