/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { Link } from "gatsby"
import { RiCloseLine, RiMenuLine } from "react-icons/ri"

const MenuItems = [
  {
    path: "/belmot",
    title: "Leistungsübersicht",
  },
  {
    path: "/fragenkatalog",
    title: "Fragenkatalog",
  },
  // {
  //   path: "/blog",
  //   title: "Blog",
  // },
  {
    path: "/kontakt",
    title: "Kontakt",
  },
  {
    path: "/formular",
    title: "Anfrageformular",
  },
]

const ListLink = props => (
  <li>
    <Link to={props.to}>{props.children}</Link>
  </li>
)

class Navigation extends React.Component {
  constructor(props) {
    super(props)
    this.state = { showMenu: false }
    this.handleToggleClick = this.handleToggleClick.bind(this)
  }

  handleToggleClick() {
    this.setState(state => ({
      showMenu: !state.showMenu,
    }))
  }

  render() {
    const listMenuItems = MenuItems.map((menuItem, index) => (
      <ListLink key={index} to={menuItem.path}>
        {menuItem.title}
      </ListLink>
    ))
    return (
      <nav className="site-navigation">
        <button
          onClick={this.handleToggleClick}
          className={"menu-trigger" + (this.state.showMenu ? " is-active" : "")}
          aria-label="menü"
        >
          <div className="icon-menu-line">
            <RiMenuLine />
          </div>
          <div className="icon-menu-close">
            <RiCloseLine />
          </div>
        </button>
        <ul>{listMenuItems}</ul>
      </nav>
    )
  }
}

export default Navigation
